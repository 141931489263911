import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../layouts"
import "../css/post.css"
import SEO from "../components/seo"
import CardSliderComponent from "../components/card/CardSliderComponent"

class PostTemplate extends Component {
  render() {    
    const site = this.props.data.site.siteMetadata;
    const post = this.props.data.wpPost;
    const fluid = post.featuredImage.node.localFile.childImageSharp.fixed
    const regex = /(<[^>]*>|\\n)/g;
    let excerpt = "";
    if(post.excerpt){
    excerpt = post.excerpt.replace(regex,"");
    }
    
    return (
      <Layout>
        <SEO ogImage={site.siteUrl+fluid.src} ogUrl={this.props.location.href} title={post.title} type="article" description={excerpt} />
        <main>
          <header className="postHeader">
            <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
            <img 
            srcSet={fluid.srcSet} 
            src={fluid.src}  alt={post.featuredImage.node.alt_text} />
          </header>
          <section className="postContainer">
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
          </section>
          <section>
            <h2 className="latestArticles" >Latest Articles</h2>
            <CardSliderComponent slidesToShow={3} posts={this.props.data.allWpPost.edges} />
          </section>
        </main>
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      excerpt
      title
      content
      featuredImage {
        node {
        altText
        localFile {
          childImageSharp {
            fixed(toFormat: PNG) {
              src
              srcSet
            }
          }
        }
      }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allWpPost(filter: {id: {ne: $id}}, sort: {fields: [date], order: DESC}, limit: 3) {
      edges {
        node {
          title
          excerpt
          slug
          date(formatString: "DD-MMM-YY")
          featuredImage {
            node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 310 ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            }
          }
        }
      }
    }
  }
`
