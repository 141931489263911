import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import "@wordpress/block-library/build-style/style.css"
import "../css/app.css"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import Logo from '../components/Logo'

export default function DefaultLayout(props) {
  return (
    <div className="grid">
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
      </Helmet>
      <Header />
      <div className="logo-grid">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="body">
        <div>{props.children}</div>
      </div>
      <Footer />
    </div>
  )
}